// 1 ACRE=2.5 BIGHA
// 1 hectare = 2.47 acres
// 100 cents = 1 acre
// 1 ACRE= 40 GUNTAS

export function convertBighaToAcre(bigha) {
  return bigha * 0.4;
}

export function convertAcreToBigha(acre) {
  return acre * 2.5;
}

export function convertHectareToAcre(hectare) {
  // hectare = 2.25
  // return 0.9
  return hectare * 0.405;
}

export function convertAcreToHectare(acre) {
  // acre = 2.25
  // return 0.9
  return acre * 0.4047;
}

export function convertAcreToAcreGunta(acre) {
  // acre = 2.25
  // return { acre: 2, gunta: 10 }
  return {
    acre: Math.floor(acre),
    gunta: Math.round((acre - Math.floor(acre)) * 40),
  }; // 1 ACRE = 40 GUNTAS
}


export function convertAcreToAcreCent(acre) {
  // acre = 2.25
  // return { acre: 2, cent: 25 }
  return { acre: Math.floor(acre), cent: Math.round((acre - Math.floor(acre)) * 100) }; // 100 cents = 1 acre
}


export function convertAcreCentToAcre(acre, cent) {
  return Number(cent) / 100 + Number(acre); // 100 cents = 1 acre
}

export function convertAcreGuntaToAcre(acre, gunta) {
  // acre = 2, gunta = 20
  // return 2.5

  return Number(gunta) / 40 + Number(acre); // 1 ACRE = 40 GUNTAS
}


